export default {
    $elems: {
        $bodyEl: document.querySelector('body')
    },

    selectors: {},

    elCls: {
        scrollBodyCls: 'scrolled'
    },

    events: {
        scroll: 'scroll'
    },

    init() {
        this.addEventListeners();
    },

    addEventListeners() {
        window.addEventListener(this.events.scroll, this.scrollTopPositionDetect.bind(this), {passive: true});
    },

    scrollTopPositionDetect() {
        if (!window.scrollY) {
            this.$elems.$bodyEl.classList.remove(this.elCls.scrollBodyCls);
        } else {
            this.$elems.$bodyEl.classList.add(this.elCls.scrollBodyCls);
        }
    },
}
