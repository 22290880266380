export default Object.freeze({
    breakpoints: { //sizes in rem
        large: {
            minWidth: 1201
        },
        desktop: {
            maxWidth: 1200,
            minWidth: 1025
        },
        mobile: {
            maxWidth: 1024
        },
        tabletPortrait: {
            maxWidth: 1024,
            minWidth: 768
        },
        phone: {
            maxWidth: 767
        },
        phonePortrait: {
            maxWidth: 480
        },
    },
    transition: {
        fast: 200,
        moderate: 400,
        long: 600
    },
    visibilityCls: {
        hidden: 'hide-js',
        hiddenDesktop: 'hidden-desktop',
        hiddenMobile: 'hidden-mobile',
        hiddenNotMobile: 'hidden-not-mobile'
    }
});