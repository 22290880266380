import helper from "./../util/helper";
import appConfig from "../config";

export default {
    $elems: {
        $bodyEl: null,
        $headerEl: null,
        $navEl: null,
        $menuMainLinks: null,
        $menuLinksAnchorEl: null,
        $menuBtnEl: null,
        $currentSubMenuEl: null,
        $currentBackSubMenuBtn: null,
    },

    elCls: {
        openBurgerBtnCls: 'burger-open',
        openHeaderCls: 'header-main-open',
        openBodyCls: 'header-main-nav-open',
        subMenuCls: 'sub-menu',
        openSubMenuCls: 'sub-menu-open'
    },

    selectors: {
        headerSel: 'header.header-main',
        burgerSel: '.burger',
        navSel: 'nav.header-main-nav',
        menuLinksSel: '.menu>li.menu-item.menu-has-dropdown>a',
        menuLinksAnchorSel: '.menu>li.menu-item>a[href^="#"]',
        subMenuSel: '.sub-menu',
        backMenuSel: '.back-menu'
    },

    boundHandlers: {
        boundHandleCurrentBackSubMenuBtn: null,
        boundHandleWindowResize: null,
    },

    init() {
        this.$elems.$bodyEl = document.querySelector('body');
        this.$elems.$headerEl = this.$elems.$bodyEl.querySelector(this.selectors.headerSel);
        this.$elems.$menuBtnEl = this.$elems.$headerEl.querySelector(this.selectors.burgerSel);

        if (!(this.$elems.$headerEl && this.$elems.$menuBtnEl)) {
            this.$elems.$bodyEl = null;
            this.$elems.$headerEl = null;
            this.$elems.$menuBtnEl = null;

            return;
        }

        this.$elems.$navEl = this.$elems.$headerEl.querySelector(this.selectors.navSel);

        if (!this.$elems.$navEl) {
            this.$elems.$menuBtnEl.outerHTML = '';

            return;
        }

        this.$elems.$menuMainLinks = this.$elems.$navEl.querySelectorAll(this.selectors.menuLinksSel);
        this.$elems.$menuLinksAnchorEl = this.$elems.$navEl.querySelectorAll(this.selectors.menuLinksAnchorSel);

        this.boundHandlers.boundHandleWindowResize = this.handleWindowResize.bind(this);
        this.boundHandlers.boundHandleCurrentBackSubMenuBtn = this.handleCurrentBackSubMenuBtn.bind(this);

        window.addEventListener('resize', this.boundHandlers.boundHandleWindowResize);
        this.$elems.$menuBtnEl.addEventListener('click', this.handleBurgerBtnClick.bind(this));
        this.$elems.$menuMainLinks.forEach(link =>
            link.addEventListener('click', this.handleSubMenuEvents.bind(this))
        );
        this.$elems.$menuLinksAnchorEl.forEach(link =>
            link.addEventListener('click', this.handleAnchorsClicks.bind(this))
        );
    },

    handleBurgerBtnClick(event) {
        if (helper.getWindowWidthInRem() > appConfig.breakpoints.mobile.maxWidth) {
            return;
        }

        if (this.$elems.$currentBackSubMenuBtn) {
            this.$elems.$currentBackSubMenuBtn.removeEventListener('click',
                this.boundHandlers.boundHandleCurrentBackSubMenuBtn, false);
        }

        if (this.$elems.$currentSubMenuEl) {
            this.handleCurrentBackSubMenuBtn();
        }

        this.$elems.$bodyEl.classList.toggle(this.elCls.openBodyCls);
        this.$elems.$menuBtnEl.classList.toggle(this.elCls.openBurgerBtnCls);
        this.$elems.$headerEl.classList.toggle(this.elCls.openHeaderCls);
    },

    handleSubMenuEvents(event) {
        if (helper.getWindowWidthInRem() > appConfig.breakpoints.mobile.maxWidth) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        this.$elems.$currentSubMenuEl = event.target.parentElement.querySelector(this.selectors.subMenuSel);
        this.$elems.$currentBackSubMenuBtn = this.$elems.$currentSubMenuEl.querySelector(this.selectors.backMenuSel);

        this.$elems.$currentSubMenuEl.parentElement.classList.add(this.elCls.openSubMenuCls);

        //handle back button click once
        //@todo is it spaghetti? yes it is.
        this.$elems.$currentBackSubMenuBtn.addEventListener('click',
            this.boundHandlers.boundHandleCurrentBackSubMenuBtn, {once: true});
    },

    handleAnchorsClicks(event) {
        this.handleBurgerBtnClick();
    },

    handleCurrentBackSubMenuBtn() {
        if (!this.$elems.$currentSubMenuEl) {
            return;
        }

        this.$elems.$currentSubMenuEl.parentElement.classList.remove(this.elCls.openSubMenuCls);
        this.$elems.$currentSubMenuEl = null;
        this.$elems.$currentBackSubMenuBtn = null;
    },

    handleWindowResize(event) {
        if (helper.getWindowWidthInRem() < appConfig.breakpoints.mobile.maxWidth) {
            return;
        }

        this.destroyMobileView();
    },

    destroyMobileView() {
        this.handleCurrentBackSubMenuBtn();

        this.$elems.$bodyEl.classList.remove(this.elCls.openBodyCls);
        this.$elems.$menuBtnEl.classList.remove(this.elCls.openBurgerBtnCls);
        this.$elems.$headerEl.classList.remove(this.elCls.openHeaderCls);
    }
};